import {createRouter, createWebHistory,createWebHashHistory} from 'vue-router'

// const routerHistory = createWebHistory()
const routerHistory = createWebHashHistory()


const routes = [
  {
    path: '/',
    redirect: '/index'
  }
  ,{
    path:'/login',
    name:'Login',
    component:() => import('./pages/login/login.vue')
  }
  ,{
    path:'/index',
    name:'Index',
    component:() => import('./pages/index/index.vue')
  }
  ,{
    path:'/mobile',
    name:'Mobile',
    component:() => import('./pages/mobile/index.vue')
  }
]

const router = createRouter({
  history: routerHistory,
  routes
})



export default router